<template>
    <div class="metope-order">
        <div class="container">
            <!-- 标题 -->
            <div class="metope-order-title">确认订单</div>
            <!-- 订单内容模块 -->
            <metope-order-info
                :metope-order="metopeOrders">
            </metope-order-info>
            <!-- 订单表单模块 -->
            <metope-order-form
                ref="orderForm"
                :user-phone="metopeOrders.userPhone"
                :user-name="metopeOrders.userName"
                :user-area="metopeOrders.userArea"
                :user-address="metopeOrders.userAddress"
				order-type='wallpaper'>
            </metope-order-form>
            <!-- 结尾 -->
            <div class="metope-order-end">
                <div class="metope-order-end-price">
                    <span>{{metopeOrders.count == 0? '定金': '合计'}}价格：</span>
                    <span class="metope-order-end-price-num">{{metopeOrders.total}}</span>
                    <span>元</span>
                </div>
                <el-button
                    type="primary"
                    size="medium"
                    @click="handleSubmit"
                    class="metope-order-end-submit">
                    提交
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        computed: mapState({
            metopeOrder: state => state.metope.metopeOrder
        }),
        components: {
            metopeOrderInfo: () => import("@/components/metope/metopeOrderInfo"),
            metopeOrderForm: () => import("@/components/metope/metopeOrderForm")
        },
		data() {
			return {
				metopeOrders:{}
			}
		},
		watch:{
			metopeOrder(){
				this.metopeOrders = this.metopeOrder
			}
		},
		mounted() {
			this.metopeOrders = this.metopeOrder
			
		},
        methods: {
            ...mapMutations([
                'setMetopeOrder'
            ]),
            ...mapActions([
                'getMetopeOrder',
                'handleSubmitMetopeOrder'
            ]),
            handleSubmit(){
                let form = this.$refs.orderForm.form,
                    orderID = this.metopeOrder.orderID;
                this.handleSubmitMetopeOrder({
                    orderID,
                    know_size: form.need_size,
                    size: form.size,
                    name: form.name,
                    phone: form.phone,
                    city: form.city,
                    address: form.address,
                    remark: form.remark
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type? 'success': 'error'
                    })
                    if(res.type){
                        this.$router.push({
                            name: 'metopeOrder',
                            query: {
                                id: orderID
                            }
                        })
                    }
                }).catch(err => {})
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.getMetopeOrder(to.query.id)
            })
        },
        beforeRouteLeave (to, from, next) {
            this.setMetopeOrder({})
            next();
        }
    }
</script>

<style scoped>
.metope-order{
    --color: #57c;
    padding: 50px 0;
    background-color: #fafafa;
}
.metope-order-title{
    margin-bottom: 20px;
    letter-spacing: 4px;
    font-size: 20px;
}
.metope-order-end{
    margin-top: 20px;
    text-align: right;
}
.metope-order-end-price > span{
    letter-spacing: 2px;
    font-size: 16px;
}
.metope-order-end-price > .metope-order-end-price-num{
    margin-right: 6px;
    font-size: 22px;
    color: var(--color);
}
.metope-order-end-submit{
    width: 200px;
    margin-top: 20px;
    background-color: #325bc4;
    border-color: #325bc4;
    letter-spacing: 4px;
}
.metope-order-end-submit >>> span{
    font-size: 16px;
}
</style>